import type { Invoice, InvoiceResource } from '@amici/myamici-api-client'
import type { ReactElement } from 'react'
import styles from '../assets/scss/OrderInvoicesListView.module.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { useEnv } from '../../common/hooks/useEnv'
import MaDescriptionList from '../../common/components/MaDescriptionList'

function OrderInvoiceListEntry ({
  invoice,
  isFinanceUser
}: Readonly<{ invoice: Invoice, isFinanceUser: boolean }>): ReactElement {
  const { t } = useTranslation()
  const { REACT_APP_MA3_BASE_URL } = useEnv()

  return (
    <li>
      <MaDescriptionList
        items={[
          {
            term: t('purchase_order.invoice.number'),
            details: isFinanceUser
              ? (
              <Link
                to={`${REACT_APP_MA3_BASE_URL}/Invoice/InvoicePage2.aspx?InvoiceId=${invoice.id}`}
                target="_blank"
              >
                {invoice.number}
              </Link>
                )
              : (
              <span>{invoice.number}</span>
                )
          },
          {
            term: t('purchase_order.invoice.date'),
            details: format(parseISO(invoice.invoice_date ?? ''), 'd MMM yyyy')
          },
          {
            term: t('purchase_order.invoice.status'),
            details: t(
              `purchase_order.invoice.status.values.${invoice.status?.toLowerCase()}`
            )
          },
          {
            term: t('purchase_order.invoice.net'),
            details: isFinanceUser
              ? t('common.price', {
                price: invoice.net,
                currency: invoice.currency
              })
              : '-'
          },
          {
            term: t('purchase_order.invoice.gross'),
            details: isFinanceUser
              ? t('common.price', {
                price: invoice.gross,
                currency: invoice.currency
              })
              : '-'
          },
          {
            term: t('purchase_order.invoice.added_by'),
            details: invoice.added_by?.name
          }
        ]}
      />
    </li>
  )
}

function OrderInvoiceListView ({
  invoices,
  isFinanceUser
}: Readonly<{
  invoices: InvoiceResource[]
  isFinanceUser: boolean
}>): ReactElement {
  return (
    <ol className={styles['order-invoices-list']}>
      {invoices.map(invoice => (
        <OrderInvoiceListEntry
          key={invoice.id}
          invoice={invoice}
          isFinanceUser={isFinanceUser}
        />
      ))}
    </ol>
  )
}

export default OrderInvoiceListView
