import LoadingSpinner from '../../common/components/LoadingSpinner'
import type { OrderResource } from '@amici/myamici-api-client'
import { type ReactElement, useRef } from 'react'
import { Trans } from 'react-i18next'
import useInvoices from '../hooks/useInvoices'
import OrderInvoiceTable from './OrderInvoiceTable'
import styles from '../assets/scss/OrderInvoicesTable.module.scss'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import OrderInvoiceListView from './OrderInvoiceListView'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import useAccounts from '../../common/hooks/useAccounts'

const FULL_VIEW_MIN_WIDTH_PX = 705

interface OrderHistoryProps {
  order: OrderResource
}

function OrderInvoices ({ order }: Readonly<OrderHistoryProps>): ReactElement {
  const { invoices, isLoading } = useInvoices(order.id)
  const { activeAccount } = useAccounts()
  const { isFinanceUser } = usePurchasingPermissions(activeAccount)

  const ref = useRef(null)
  const fullView = useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!isLoading && invoices.length === 0) {
    return (
      <div className={styles['no-results-message']}>
        <Trans i18nKey="purchase_order.invoice.no_results" />
      </div>
    )
  }

  return (
    <div ref={ref}>
      {fullView
        ? (
        <OrderInvoiceTable invoices={invoices} isFinanceUser={isFinanceUser} />
          )
        : (
        <OrderInvoiceListView
          invoices={invoices}
          isFinanceUser={isFinanceUser}
        />
          )}
    </div>
  )
}

export default OrderInvoices
