import type {
  OrderLineItemResource,
  OrderResource
} from '@amici/myamici-api-client'
import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MaTab from '../../common/models/MaTab'
import OrderHistory from './OrderHistory'
import MaPanel from '../../common/components/MaPanel'
import MaDetailsTabs from '../../common/components/MaDetailsTabs'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import useAccounts from '../../common/hooks/useAccounts'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'
import OrderInvoices from './OrderInvoices'

enum PurchaseOrderDetailsTab {
  HISTORY = 'history',
  INVOICE = 'invoice',
}

interface PurchaseOrderDetailsPanelProps {
  order: OrderResource
  lineItems?: OrderLineItemResource[]
}

function PurchaseOrderAdditionalDetailsPanel ({
  order,
  lineItems
}: Readonly<PurchaseOrderDetailsPanelProps>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { hasFinanceModule } = usePurchasingPermissions(activeAccount)
  const [activeTab, setActiveTab] = useState(PurchaseOrderDetailsTab.HISTORY)

  const title = t('order.additional_details.title')
  const tabs = [
    new MaTab(
      PurchaseOrderDetailsTab.HISTORY,
      <OrderHistory order={order} lineItems={lineItems} />
    )
  ]
  if (hasFinanceModule) {
    tabs.push(
      new MaTab(
        PurchaseOrderDetailsTab.INVOICE,
        <OrderInvoices order={order} />
      )
    )
  }
  return (
    <MaPanel title={title}>
      <MaPanel.Header text={title} className={styles.header} />
      <MaPanel.Body>
        <MaDetailsTabs
          tabs={tabs}
          activeTab={activeTab}
          onSelectTab={tabKey => {
            setActiveTab(tabKey as PurchaseOrderDetailsTab)
          }}
        />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderAdditionalDetailsPanel
