import React, {
  type ReactElement,
  type ReactNode,
  createContext,
  useState,
  useContext,
  type PropsWithChildren
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ToastContainer } from 'react-bootstrap'
import { type Variant } from 'react-bootstrap/types'
import MaToast from './MaToast'
import styles from '../assets/scss/MaToast.module.scss'

interface ToastNotificationContextProps {
  showToastMessage: (type: Variant, message: ReactNode) => number | string
}

export const ToastNotificationContext = createContext<
ToastNotificationContextProps | undefined
>(undefined)

export const useToastNotification = (): ToastNotificationContextProps => {
  const context = useContext(ToastNotificationContext)
  if (!context) {
    throw new Error(
      'useToastNotification must be used within a ToastNotificationContextProvider'
    )
  }
  return context
}

function ToastNotificationContextProvider ({
  children
}: Readonly<PropsWithChildren>): ReactElement {
  const [toastList, setToastList] = useState(
    new Map<number | string, ReactElement>()
  )

  const showToast = (toastId: string, toast: ReactElement): void => {
    setToastList(toastList => {
      const newToastList = new Map(toastList)
      newToastList.set(toastId, toast)
      return newToastList
    })
  }

  const closeToast = (toastId: string): void => {
    setToastList(toastList => {
      const newToastList = new Map(toastList)
      newToastList.delete(toastId)
      return newToastList
    })
  }

  const showToastMessage = (type: Variant, message: ReactNode): string => {
    const toastId: string = uuidv4()
    showToast(
      toastId,
      <MaToast
        type={type}
        onClose={() => {
          closeToast(toastId)
        }}
      >
        {typeof message === 'string' || typeof message === 'number'
          ? (
          <span>{message}</span>
            )
          : (
              message
            )}
      </MaToast>
    )
    return toastId
  }

  return (
    <ToastNotificationContext.Provider value={{ showToastMessage }}>
      {children}
      <ToastContainer className={styles['toast-container']}>
        {[...toastList.entries()].reverse().map(([key, toast]) => (
          <React.Fragment key={key}>{toast}</React.Fragment>
        ))}
      </ToastContainer>
    </ToastNotificationContext.Provider>
  )
}

export default ToastNotificationContextProvider
