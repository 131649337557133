import useSWR from 'swr'
import { type InvoiceResource, type Invoices } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseInvoicesHook {
  invoices: InvoiceResource[]
  error?: Error
  isLoading: boolean
}

function useInvoices (orderId: string): UseInvoicesHook {
  const { activeAccount } = useAccounts()
  const {
    ordersApi: { getOrderInvoices },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWR<Invoices, Error>(
    orderId && accountId ? ['invoices', orderId, accountId] : null,
    async () =>
      await fetcher(getOrderInvoices, { orderId, accountId, size: 0 })
  )

  const invoices = data?.content ?? []

  return {
    invoices,
    isLoading,
    error
  }
}

export default useInvoices
