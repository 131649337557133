import { type FormEvent, type ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { type ColumnSort, type Row as DataRow } from '@tanstack/react-table'
import { Button, Col, Container, Row } from 'react-bootstrap'
import {
  type Order,
  type DeliveryReceipt,
  type LineItemResource,
  type Product
} from '@amici/myamici-api-client'
import { formatISO } from 'date-fns'
import { useLocalStorage } from 'usehooks-ts'
import { BsGrid, BsList } from 'react-icons/bs'
import { type DateRange } from 'react-day-picker'
import sortingOptions from '../types/sorting-options'
import { OutstandingOrderItemsColumnId as ColumnId } from '../types/outstanding-order-items-column-id'
import { UserPermission } from '../../common/types/user-permission'
import useIsMobile from '../../common/hooks/useIsMobile'
import useOutstandingOrderItems from '../../outstanding-order-items/hooks/useOutstandingOrderItems'
import useAccounts from '../../common/hooks/useAccounts'
import useReceiveLineItems from '../../purchasing/hooks/useReceiveLineItems'
import MaPageTitle from '../../common/components/MaPageTitle'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import MaDateRangePicker from '../../common/components/MaDateRangePicker'
import ToggleButtonGroup from '../../common/components/ToggleButtonGroup'
import OrderItemsTable from './OrderItemsTable'
import OrderItemCard, { type CardItem } from './OrderItemCard'
import SearchForm from '../../purchasing/components/SearchForm'
import OrderItemsPagination from './OrderItemsPagination'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import ReceiveLineItemsModal from '../../purchasing/components/ReceiveLineItemsModal'
import styles from '../assets/scss/OutstandingOrderItems.module.scss'

enum OrderItemView {
  Cards,
  TableList,
}

const LOCAL_STORAGE_CARD_VIEW_KEY = 'outstandingOrderItemsCardView'

export function mapTableRowToCard (row: DataRow<LineItemResource>): CardItem {
  return {
    orderId: row.original.order?.id ?? '',
    productId: row.original.product.id,
    [ColumnId.DESCRIPTION]: row.original.product.description ?? '',
    [ColumnId.REFERENCE]: row.getValue(ColumnId.REFERENCE),
    [ColumnId.PART_NUMBER]: row.getValue(ColumnId.PART_NUMBER),
    [ColumnId.SUPPLIER]: row.getValue(ColumnId.SUPPLIER),
    [ColumnId.QUANTITY]: row.getValue(ColumnId.QUANTITY),
    [ColumnId.ESTIMATED_DELIVERY_DATE]:
      row.original.estimated_delivery_date ?? '',
    [ColumnId.ISSUES]: row.getValue(ColumnId.ISSUES),
    imageUrl: row.original.image_url ?? '',
    isSelfServe: row.original.is_self_serve ?? false
  }
}

function OutstandingOrderItems (): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { activeAccount } = useAccounts()
  const [searchParams] = useSearchParams()
  const presetFilter = searchParams.get('preset') ?? ''
  const searchParam = searchParams.get('search') ?? ''
  const [cardView, setCardView] = useLocalStorage(
    LOCAL_STORAGE_CARD_VIEW_KEY,
    true
  )
  const {
    table,
    sorting,
    filters,
    totalCount,
    totalPages,
    page,
    size,
    mutate,
    changePage,
    changePageSize,
    changeSearch,
    changeSorting,
    changeDateFilter,
    isLoading
  } = useOutstandingOrderItems(presetFilter)
  const { submit } = useReceiveLineItems()
  const { showToastMessage } = useToastNotification()
  const [search, setSearch] = useState(searchParam)
  const [lineItemsToReceive, setLineItemsToReceive] = useState<
  LineItemResource[]
  >([])

  useEffect(() => {
    setSearch(searchParam)
  }, [searchParam])

  useEffect(() => {
    if (totalCount > 0 && page > 1 && table.getRowModel().rows.length === 0) {
      changePage(page - 1)
    }
  }, [changePage, page, table, totalCount])

  const dateRangeFilterValue = filters.find(
    filter => filter.id === ColumnId.ESTIMATED_DELIVERY_DATE
  )?.value as DateRange | undefined

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchParam && !search.trim()) {
      return
    }

    changeSearch(search.trim())
    setSearch(search.trim())
  }

  const handleSortingChange = (sort: string): void => {
    if (sort) {
      changeSorting(JSON.parse(sort) as ColumnSort)
    }
  }

  const handleViewChange = (): void => {
    setCardView((cardView: boolean) => !cardView)
  }

  const handleReceiveItemsModalOpen = (lineItem: LineItemResource): void => {
    setLineItemsToReceive([lineItem])
  }

  const handleReceiveItemsModalClose = (): void => {
    setLineItemsToReceive([])
  }

  const getItemProduct = (item: DeliveryReceipt): Product | undefined =>
    table
      .getPrePaginationRowModel()
      .rows.find(row => row.original.id === item.line_item_id)?.original.product

  const handleSubmissionSuccess = async (
    items: DeliveryReceipt[]
  ): Promise<void> => {
    const [item] = items
    const product = getItemProduct(item)
    const isCommentOnly = !!item.comment && item.quantity === 0

    setLineItemsToReceive([])
    await mutate()
    showReceivedItemsSuccessNotification(product?.description, isCommentOnly)
  }

  const handleSubmissionError = (items: DeliveryReceipt[]): void => {
    const [item] = items
    const product = getItemProduct(item)

    setLineItemsToReceive([])
    showReceivedItemsErrorNotification(product?.description)
  }

  const handleReceiveAllOutstandingItems = async (
    lineItem: LineItemResource
  ): Promise<void> => {
    const today = new Date()
    const orderId = lineItem.order?.id
    const items: DeliveryReceipt[] = [
      {
        line_item_id: lineItem.id,
        quantity: lineItem.quantity - (lineItem.quantity_received ?? 0),
        date: formatISO(today, { representation: 'date' })
      }
    ]

    try {
      await submit(orderId, items)
      await handleSubmissionSuccess(items)
    } catch (error) {
      handleSubmissionError(items)
    }
  }

  const showReceivedItemsSuccessNotification = (
    productName?: string | null,
    isCommentOnly?: boolean
  ): void => {
    const message = isCommentOnly
      ? t('receive_order.item.add_comment_success_message')
      : t('receive_order.item.success_message', {
        reference: productName
      })

    showToastMessage('dark', message)
  }

  const showReceivedItemsErrorNotification = (
    productName?: string | null
  ): void => {
    showToastMessage(
      'danger',
      t('receive_order.item.error_message', {
        reference: productName
      })
    )
  }

  const displayEmptySortingOption = !sortingOptions.find(
    option => JSON.stringify(option.value) === JSON.stringify(sorting[0])
  )

  const pageStartRow = (page - 1) * size + 1
  const pageEndRow =
    pageStartRow + table.getPaginationRowModel().rows.length - 1

  const noMatchingSearch = !isLoading && searchParam && !totalCount

  const canReceiveItems = activeAccount?.permissions.includes(
    UserPermission.IsGoodsInUser
  )

  return (
    <Container fluid="auto" className="ma-page">
      <MaPageTitle>{t('outstanding_order_items.title')}</MaPageTitle>

      <Row className={styles['filter-controls-row']}>
        <Col md={5} lg={6} className={styles['text-filter']}>
          <SearchForm
            search={search}
            placeholder={t('outstanding_order_items.search')}
            onSearchChange={e => {
              setSearch(e.target.value)
            }}
            onSubmit={handleSearchSubmit}
          />
        </Col>

        <Col md={7} lg={6} className={styles['filter-controls']}>
          <MaDateRangePicker
            value={dateRangeFilterValue}
            className={styles['daterange-filter']}
            onDateRangeChange={changeDateFilter}
            placeholder={t('outstanding_order_items.date_range.placeholder')}
          />

          {!isMobile && (
            <ToggleButtonGroup
              onClick={handleViewChange}
              selectedBtnIndex={
                cardView ? OrderItemView.Cards : OrderItemView.TableList
              }
            >
              <BsGrid size={24} title={t('common.view.card')} />
              <BsList size={24} title={t('common.view.table')} />
            </ToggleButtonGroup>
          )}
        </Col>
      </Row>

      <div className={styles['items-view']}>
        {isLoading && (
          <div className={styles.loading}>
            <LoadingSpinner />
          </div>
        )}

        {!isLoading && (
          <div className={styles.summary}>
            {noMatchingSearch && (
              <p className={styles['count-label']}>
                {t('outstanding_order_items.total.no_items_found')}
              </p>
            )}

            {!noMatchingSearch && (
              <p className={styles['count-label']}>
                {!isLoading &&
                  presetFilter &&
                  t(
                    `outstanding_order_items.prefiltered.${presetFilter}.count`,
                    {
                      count: totalCount
                    }
                  )}
                {!isLoading &&
                  !presetFilter &&
                  t('outstanding_order_items.total.count', {
                    count: totalCount
                  })}
              </p>
            )}

            {(cardView || isMobile) && totalCount > 0 && (
              <div className={styles['sort-wrapper']}>
                <MaSelect
                  aria-label={t('common.label.sort_by')}
                  className={styles.sort}
                  value={
                    displayEmptySortingOption
                      ? undefined
                      : JSON.stringify(sorting[0] ?? null)
                  }
                  placeholder={t('common.label.sort_by')}
                  label={t('common.label.sort_by')}
                  onValueChange={handleSortingChange}
                >
                  {sortingOptions.map(option => (
                    <MaSelectItem
                      key={option.name}
                      value={JSON.stringify(option.value)}
                    >
                      {t(option.name)}
                    </MaSelectItem>
                  ))}
                </MaSelect>
              </div>
            )}
          </div>
        )}

        {cardView || isMobile
          ? (
          <div data-testid="items-card-view">
            <Row>
              {table.getRowModel().rows.map(row => (
                <Col md={6} xxl={4} key={row.original.id}>
                  <OrderItemCard
                    item={mapTableRowToCard(row)}
                    variant={isMobile ? 'compact' : 'full'}
                    actions={
                      <>
                        <Button
                          disabled={!canReceiveItems}
                          variant="light"
                          className="rounded"
                          onClick={() => {
                            void handleReceiveAllOutstandingItems(row.original)
                          }}
                          data-testid="quick-receive-btn"
                        >
                          {t('order.item.action.quick_receive')}
                        </Button>
                        <Button
                          disabled={!canReceiveItems}
                          variant="primary"
                          className="rounded"
                          onClick={() => {
                            handleReceiveItemsModalOpen(row.original)
                          }}
                          data-testid="receive-and-edit-btn"
                        >
                          {t('order.item.action.receive_and_edit')}
                        </Button>
                      </>
                    }
                  />
                </Col>
              ))}
            </Row>
          </div>
            )
          : (
          <OrderItemsTable
            table={table}
            canReceiveItems={!!canReceiveItems}
            onSortingChange={changeSorting}
            onQuickReceive={handleReceiveAllOutstandingItems}
            onReceiveAndEdit={handleReceiveItemsModalOpen}
          />
            )}

        {totalCount > 0 && (
          <OrderItemsPagination
            standalone={cardView || isMobile}
            totalPages={totalPages}
            currentPage={page}
            pageSize={size}
            pageStartRow={pageStartRow}
            pageEndRow={pageEndRow}
            totalRowsCount={totalCount}
            onPageChange={changePage}
            onPageSizeChange={changePageSize}
          />
        )}

        {lineItemsToReceive.length > 0 && (
          <ReceiveLineItemsModal
            variant="item"
            order={lineItemsToReceive[0].order as Order}
            lineItems={lineItemsToReceive}
            onClose={handleReceiveItemsModalClose}
            onSuccess={(items: DeliveryReceipt[]) => {
              void handleSubmissionSuccess(items)
            }}
            onError={(items: DeliveryReceipt[]) => {
              handleSubmissionError(items)
            }}
          />
        )}
      </div>
    </Container>
  )
}

export default OutstandingOrderItems
