import { type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  type BasketLineItem,
  type LineItem,
  type ProductResource,
  type StockCheckResource
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import useBasketSettings from '../../basket/hooks/useBasketSettings'
import useBasketPreview from '../../basket/hooks/useBasketPreview'
import useBasketCount from '../../basket/hooks/useBasketCount'
import MaAddQuantity from '../../common/components/MaAddQuantity'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useProductPrice from '../hooks/useProductPrice'

function AddToBasket ({
  product,
  stockCheckResource
}: Readonly<{
  product: ProductResource
  stockCheckResource?: StockCheckResource
}>): ReactElement {
  const { t } = useTranslation()
  const { basketApi } = useApi()
  const { activeAccount } = useAccounts()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const { mutate: updateBasketCount } = useBasketCount()
  const { basketUrl, target } = useBasketSettings()
  const { showToastMessage } = useToastNotification()
  const [basketPending, setBasketPending] = useState(false)
  const productPriceData = useProductPrice(
    product.id,
    product.supplier?.currency ?? 'GBP',
    null,
    null
  )

  const accountId = activeAccount?.accountId ?? ''

  const handleAddToBasket = async (quantity: number): Promise<void> => {
    if (!activeAccount?.accountId) {
      return
    }

    const lineItem: LineItem = {
      // The id field is required, setting to '0' for the new line items
      id: '0',
      product: {
        ...product
      },
      quantity,
      // TODO: Including these for now to pass the endpoint validation -
      // it is preferable to adjust the model to make these fields optional
      issues: [],
      spend_categories: []
    }

    const basketLineItem: BasketLineItem = {
      line_item: lineItem,
      product_snapshot: {
        ...product
      },
      product_stock: {
        // The id field is required, setting to '0' for the product stock
        id: stockCheckResource?.id ?? '0',
        availability: stockCheckResource?.availability,
        lead_time: stockCheckResource?.lead_time,
        location: stockCheckResource?.location,
        maximum_quantity: stockCheckResource?.minimum_quantity,
        minimum_quantity: stockCheckResource?.minimum_quantity,
        estimated_shipping_date: stockCheckResource?.estimated_shipping_date
      },
      product_price: productPriceData.bestPrice ?? 0
    }

    try {
      setBasketPending(true)
      await basketApi.addLineItemToBasket({ accountId, basketLineItem })
      await updateBasketPreview()
      await updateBasketCount()

      showToastMessage(
        'dark',
        <>
          <p>
            {t('product.details.add_to_basket.success', { count: quantity })}
          </p>
          <Link to={basketUrl} target={target}>
            {t('product.details.add_to_basket.basket_link')}
          </Link>
        </>
      )
    } catch (e) {
      showToastMessage('danger', t('product.details.add_to_basket.failure'))
    } finally {
      setBasketPending(false)
    }
  }

  return (
    <MaAddQuantity
      label={t('product.details.quantity')}
      ctaLabel={t('product.details.add_to_basket')}
      disabled={basketPending}
      onSubmit={quantity => {
        void handleAddToBasket(quantity)
      }}
    />
  )
}

export default AddToBasket
